<template>
    <div class="standards">
        <el-tabs v-model="menuTitle" @tab-click="subMenuHandle">
            <el-tab-pane label="公开文件" name="publicdocuments">
            </el-tab-pane>
            <el-tab-pane label="内部文件" name="internaldocuments">
            </el-tab-pane>
        </el-tabs>
        <el-form>
            <div class="head_form">
                <div>
                    <el-form-item label="项目名称">
                        <el-input v-model="formModal.name" placeholder="请输入项目名称" clearable
                            @keyup.enter.native="queryStandard()"></el-input>
                    </el-form-item>
                </div>
                <div>
                    <el-form-item label="分类">
                        <el-select v-model="formModal.type" clearable placeholder="请选择分类">
                            <el-option v-for="item in selectOptions" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div>
                    <el-form-item label="标签">
                        <el-select v-model="label" placeholder="标签检索" clearable multiple @focus="labelDataList()"
                            @change="markItemChange">
                            <el-option v-for="(item, index) in labelList" :key="index" :value="item.name"></el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="right_area">
                    <el-button type="primary" @click="keyWordQuery()">查询</el-button>
                    <el-button type="info" @click="qingkong('formModal')">清空</el-button>
                </div>
            </div>
        </el-form>
        <div class="plus_add"><el-button type="primary" icon="el-icon-plus" @click="operateFrom()">新增</el-button>
        </div>
        <div>
            <el-table :data="standardData" :row-class-name="tabRowClassName" style="width: 100%"
                :header-cell-style="{ background: '#3082E2', color: '#FFF' }">
                <!-- <el-table-column label="ID" prop="id" align="center" width="80px">
                </el-table-column> -->
                <el-table-column label="排序" prop="sort" align="center" width="50px">
                </el-table-column>
                <el-table-column label="标准编号" prop="basicid" align="left" width="160px">
                </el-table-column>
                <el-table-column label="标准名称" prop="name" align="left">
                    <template slot-scope="{ row }">

                        <el-tag size="mini" v-if="row.type == 3"
                            style="background-color: blueviolet;color:white">制定</el-tag>
                        <el-tag size="mini" v-if="row.type == 4"
                            style="background-color:chocolate;color:white">修订</el-tag>
                        <span class="subtext" @click="infoEvent(row)">{{ row.name }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="编制单位" prop="company" align="left">
                </el-table-column>

                <el-table-column label="创建时间" prop="created_at" align="center" width="130px">
                    <template slot-scope="{row}">
                        <span>{{ row.created_at | formatDate('yyyy-MM-dd') }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="标签" prop="batch" align="center" width="150px">
                    <template slot-scope="{ row }">
                        <div class="label_list">
                            <div v-for="(item, index) in row.label" :key="index" class="btns">
                                <el-tag type="info" v-if="item.label != null" size="mini"
                                    style="background-color: black;color:white">{{ item.label }} <span
                                        @click="delLabel(row, item.label)"><i class="el-icon-close"></i></span></el-tag>
                            </div>
                            <span class="lf_label" @click="addLabel(row.id)"><el-button type="text"
                                    icon="el-icon-circle-plus-outline"></el-button></span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="180px">
                    <template slot-scope="{ row }">
                        <el-tooltip class="item" effect="dark" content="编辑" placement="top">
                            <el-button icon="el-icon-edit" type="text" @click="operateFrom(row)"></el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="公开标准" placement="top">
                            <el-button icon="el-icon-view" type="text" @click="openstandard(row)"></el-button>
                        </el-tooltip>
                        <el-tooltip class="item del_icon" effect="dark" content="删除" placement="top">
                            <el-button icon="el-icon-delete" style="color: red;" type="text"
                                @click="handleDelete(row)"></el-button>
                        </el-tooltip>
                        <!-- <el-button type="primary" @click="addLabel(row.id)" size="mini">新增标签</el-button> -->
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 分页 -->
        <div class="pageTotal">
            <div class="block">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="page" layout="prev, pager, next" :total="totalCount" :page-size="limit">
                </el-pagination>
            </div>
        </div>
        <div>
            <el-dialog :visible.sync="operateVisible" custom-class="operate_dialog" :close-on-click-modal="false"
                :title="titleName" @close="infoCancle()">
                <el-form :model="editForm" ref="editForm" class="stand_form">
                    <div class="form_line">
                        <el-form-item label="标准编号" prop="basicid"
                            :rules="[{ required: true, message: '请输入标准编号', trigger: 'blur' }]">
                            <el-input v-model="editForm.basicid"></el-input>
                        </el-form-item>
                        <el-form-item label="标准名称" prop="name"
                            :rules="[{ required: true, message: '请输入标准名称', trigger: 'blur' }]">
                            <el-input v-model="editForm.name"></el-input>
                        </el-form-item>                        
                        <el-form-item label="发布时间" prop="publish_date"
                            :rules="[{ required: true, message: '请选择发布时间', trigger: 'blur' }]">
                            <el-date-picker v-model="editForm.publish_date" value-format="yyyy-MM-dd" type="date"
                                placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="实施时间" prop="action_date"
                            :rules="[{ required: true, message: '请选择实施时间', trigger: 'blur' }]">
                            <el-date-picker v-model="editForm.action_date" value-format="yyyy-MM-dd" type="date"
                                placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="主编单位" prop="company"
                            :rules="[{ required: true, message: '请输入主编单位', trigger: 'blur' }]">
                            <el-input v-model="editForm.company"></el-input>
                        </el-form-item>
                        <el-form-item label="参编单位" prop="participat_unit"
                            :rules="[{ required: false, message: '请输入参编单位', trigger: 'blur' }]">
                            <el-input v-model="editForm.participat_unit"></el-input>
                        </el-form-item>
                        <el-form-item label="类型" prop="type"
                            :rules="[{ required: true, message: '请选择类型', trigger: 'blur' }]">
                            <el-select v-model="editForm.type" >
                                <el-option v-for="item in selectOptions" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="联系方式" prop="contact_iphone"
                            :rules="[{ required: false, message: '请输入联系方式', trigger: 'blur' }]">
                            <el-input v-model="editForm.contact_iphone"></el-input>
                        </el-form-item>
                        <el-form-item label="联系地址" prop="contact_address"
                            :rules="[{ required: false, message: '请输入联系地址', trigger: 'blur' }]">
                            <el-input v-model="editForm.contact_address"></el-input>
                        </el-form-item>
                        <el-form-item label="联系QQ" prop="contact_qq"
                            :rules="[{ required: false, message: '请输入联系QQ', trigger: 'blur' }]">
                            <el-input v-model="editForm.contact_qq"></el-input>
                        </el-form-item>
                        <el-form-item label="上传文件" prop="type"
                            :rules="[{ required: true, message: '请上传文件', trigger: 'change' }]">
                            <upload-file ref="uploadWord" :fileType="fileType"
                                @standard-data="getArtFile"></upload-file>
                        </el-form-item>

                    </div>
                </el-form>

                <div slot="footer" class="dialog-footer">
                    <el-button @click="infoCancle()">取 消</el-button>
                    <el-button type="primary" @click="levelSubmit('editForm')">提 交</el-button>
                </div>
            </el-dialog>
        </div>
        <div>
            <el-dialog :visible.sync="label_show" title="新增标签" width="30%">
                <el-form>
                    <el-form-item label="标签名称" class="label_input">
                        <el-select v-model="labelSubitem" placeholder="标签检索" clearable multiple @focus="labelDataList()"
                            @change="markItemChange">
                            <el-option v-for="(item, index) in labelList" :key="index" :value="item.name"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="label_show = false">取 消</el-button>
                    <el-button type="primary" @click="label_sure">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <!-- 增加公开按钮设置 -->
        <div>
            <el-dialog :visible.sync="open_show" title="是否公开发布" width="30%">
                <el-form>
                    <el-switch v-model="openvalue" active-text="公开发布" inactive-text="不公开">
                    </el-switch>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="open_show = false">取 消</el-button>
                    <el-button type="primary" @click="sumbitopen">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import { standardsData, standardAdd, standardInfo, standardDel, standardModify } from "@api/enterprise";
import { labelSelectData, standardLabelAdd, standardLabelDel } from "@api/project";
import uploadFile from "../components/uploadFile.vue"
export default {
    filters: {
        formatDate: function (timestamp, fmt) {
            if (fmt == undefined || fmt == "" || fmt == null) {
                fmt = "yyyy-MM-dd ";
            }
            if (!timestamp) {
                return "";
            }
            let date = new Date(timestamp);
            // 第一步先替换年份 因为年份跟其他不一样是四位
            // let pat =
            if (/(y+)/.test(fmt)) {
                fmt = fmt.replace(RegExp.$1, date.getFullYear());
            }
            // 第二步匹配其他，然后替换
            let obj = {
                "M+": date.getMonth() + 1,
                "d+": date.getDate(),
                "h+": date.getHours(),
                "m+": date.getMinutes(),
                "s+": date.getSeconds(),
            };
            for (let key in obj) {
                let pat = `(${key})`;
                if (new RegExp(pat).test(fmt)) {
                    let str = obj[key] + "";
                    // RegExp.$1  MM dd hh mm ss贪婪匹配
                    fmt = fmt.replace(RegExp.$1, ("00" + str).substr(-2));
                }
            }
            return fmt;
        }
    },
    name: 'standardfile',
    // inject: ["newData"],
    components: { uploadFile },
    data() {
        return {
            openvalue: true,
            token: "",
            formModal: {
                name: "",
                type: null
            },
            menuTitle: 'publicdocuments',
            selectOptions: [
                {
                    label: '制定/省标 ',
                    value: 3,
                },
                {
                    label: ' 修订/省标',
                    value: 4,
                },
            ],
            standardData: [],
            page: 1,
            limit: 15,
            totalCount: 0,
            editForm: {
                name: "",
                basicid: "",
                company: "",
                type: "",
                action_date: "",
                publish_date: "",
                participat_unit: "",
                contact_iphone:"025-51868155",
                contact_address:"南京市鼓楼区江东北路287号银城广场B座4楼419室",
                contact_qq:"994923312（QQ）、 85397437（QQ群）"

            },
            operateVisible: false,
            fileType: "fileWord",
            fileObj: null,
            titleName: "新增",
            label_show: false,
            labelSubitem: [],
            labelList: [],
            markIds: [],
            label: "",
            open_show: false

        }
    },
    props: {

    },
    created() {

    },
    mounted() {
        this.token = this.$store.state.token; //获取cookie字符串
        this.queryStandard()
        this.labelDataList()
    },
    methods: {
        //查询
        keyWordQuery() {
            this.page = 1
            this.queryStandard()
        },
        //标题栏目切换
        subMenuHandle() {
            this.formModal = {
                name: "",
                type: null
            };
            this.editForm = {
                id: undefined,
                name: "",
                noticeid: "",
                certifiedid: "",
                company: "",
                type: ""
            };
            this.page = 1
            this.queryStandard()
        },
        //标准文本
        queryStandard() {
            // console.log(this.formModal.type, '打印类型')
            let params = {
                token: this.$store.state.token,
                page: this.page,
                limit: this.limit,
                name: this.formModal.name == '' ? undefined : this.formModal.name,
                type: this.formModal.type == null || this.formModal.type == '' ? '3,4' : this.formModal.type,
                label: this.label,
                is_public: this.menuTitle === 'publicdocuments' ? 2 : 1
            }
            standardsData(params).then(rec => {
                // console.log('标准文本', rec)
                this.standardData = rec.data.data
                this.page = rec.data.current_page //当前页
                this.totalCount = rec.data.total
                this.totalPage = rec.data.total
                this.limit = Number(rec.data.per_page)
            })
        },
        getArtFile(obj) {
            console.log(obj, '打印文件上传成功的返回')
            this.fileObj = obj
        },
        qingkong() {
            this.formModal.name = ''
            this.formModal.type = ''
            this.label = []
            this.page = 1
            this.queryStandard();
        },
        handleUpdate() {

        },
        handleDelete(row) {
            this.$confirm('是否确认删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    id: row.id,
                    token: this.$store.state.token
                }
                standardDel(data).then(rec => {
                    console.log('返回删除的结果', rec)
                    if (rec.code == 200) {
                        this.$message({
                            type: "success",
                            message: rec.message
                        })
                        this.formModal.name = ''
                        this.formModal.type = ''
                        this.queryStandard();
                    }
                })
            }).catch(() => {

            });
        },
        tabRowClassName({ rowIndex }) {
            let index = rowIndex + 1;
            if (index % 2 == 0) {
                return 'warning-row'
            }
        },
        handleSizeChange(page) {
            this.limit = page;
            this.queryStandard();
        },
        handleCurrentChange(page) {
            this.page = page;
            this.queryStandard();
        },
        // 确定标准公开
        sumbitopen(row) {
            console.log("参数", row)

            let data = {
                token: this.$store.state.token,
                // publish_state: this.editForm.publish_state.join('-'),
            }
            // standardModify(data).then(rec => {
            //     if (rec.code == 200) {
            //         this.$message({
            //             type: "success",
            //             message: rec.message
            //         })
            //         this.queryStandard();
            //     }
            // })
        },

        // 开放标准
        openstandard(row) {
            let that = this

            if (row != undefined) {
                that.sumbitopen(row)
            }
            that.open_show = true
        },

        //新增或修改
        operateFrom(row) {
            // console.log(row, '打印行信息')
            let that = this
            that.titleName = '新增'
            that.$nextTick(() => {
                if (that.$refs.uploadWord) {
                    that.$refs.uploadWord.wordList = []
                }
            })
            if (row != undefined) {
                that.titleName = '修改'
                //回显
                that.queryStandardInfo(row)
            }
            that.operateVisible = true
        },
        infoEvent(row) {
            window.open(row.files[0].url)
        },
        infoCancle() {
            this.operateVisible = false
            this.$refs.editForm.resetFields();
        },
        markItemChange(val) {
            let tagList = val
            var filterdata = this.labelList.filter(item => tagList.some(x => x === item.name))
            // console.log('打印过滤的数据', filterdata)
            this.markIds = filterdata.map(val => val.id)
        },
        //查询标签数据
        labelDataList() {
            labelSelectData({ token: this.token }).then((rec) => {
                console.log(rec, '打印标签的数据');
                this.labelList = rec.data.filter(item => item.name != null);
            });
        },
        addLabel(rowID) {
            this.label_show = true
            this.rowID = rowID
            this.labelSubitem = []
        },
        //新增标签确认
        label_sure() {
            let params = {
                id: this.rowID,
                token: this.$store.state.token,
                label: this.markIds
            }
            standardLabelAdd(params).then((rec) => {
                console.log(rec);
                if (rec.code == 200) {
                    this.$message({
                        showClose: true,
                        message: rec.message,
                        type: "success",
                    });
                    // this.formData.markList = [""]
                    this.label_show = false
                    this.queryStandard()
                }
            });
            // }
        },
        // 回显修改内容
        queryStandardInfo(row) {
            let params = {
                id: row.id,
                token: this.$store.state.token
            }
            standardInfo(params).then(rec => {
                // console.log('打印回显的内容', rec)
                this.editForm = { ...rec.data }
                this.editForm.publish_state = rec.data.publish_state.split("-"); //转数组
                this.$refs.uploadWord.wordList = rec.data.files
            })
        },
        //提交
        levelSubmit(formName) {
            // console.log('打印表单的对象', this.editForm)
            this.formModal.name = ''
            this.formModal.type = ''
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let file_name = ''
                    let url = ''
                    if (this.fileObj == null && this.editForm.id == undefined) {
                        //新增才提示
                        this.$message({
                            type: "warning",
                            message: "文件不能为空"
                        })
                        return false
                    } else if (this.fileObj != null) {
                        file_name = this.fileObj.data.name
                        url = this.fileObj.data.url
                    }
                    if (this.editForm.id === undefined) {
                        //新增
                        let data = {
                            ...this.editForm,
                            file_name: file_name,
                            standard: 1,
                            url: url,
                            basicid: this.editForm.basicid,
                            action_date: this.editForm.action_date,
                            publish_date: this.editForm.publish_date,
                            token: this.$store.state.token,
                            contact_iphone:this.editForm.contact_iphone,
                            contact_address:this.editForm.contact_address,
                            contact_qq:this.editForm.contact_qq,
                            is_public: this.menuTitle === 'publicdocuments' ? 2 : 1
                        }
                        // console.log('入参', data)
                        standardAdd(data).then(rec => {
                            // console.log('返回暂存的结果', rec)
                            if (rec.code == 200) {
                                this.$message({
                                    type: "success",
                                    message: rec.message
                                })
                                this.queryStandard();
                            }
                        })
                    } else if (this.editForm.id != undefined) {
                        //修改
                        // debugger
                        let data = {
                            ...this.editForm,
                            file_name: this.fileObj === null ? this.editForm.files[0].name : file_name,
                            url: this.fileObj === null ? this.editForm.files[0].url : url,
                            standard:1,
                            basicid: this.editForm.basicid,
                            action_date: this.editForm.action_date,
                            publish_date: this.editForm.publish_date,
                            token: this.$store.state.token,
                            contact_iphone:this.editForm.contact_iphone,
                            contact_address:this.editForm.contact_address,
                            contact_qq:this.editForm.contact_qq,
                            is_public: this.menuTitle === 'publicdocuments' ? 2 : 1
                        }
                        // console.log(data, '修改时的内容')
                        standardModify(data).then(rec => {
                            // console.log('返回暂存的结果', rec)
                            if (rec.code == 200) {
                                this.$message({
                                    type: "success",
                                    message: rec.message
                                })
                                this.queryStandard();
                            }
                        })
                    }
                    this.operateVisible = false
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        //删除标签
        delLabel(row, label) {
            let data = {
                id: row.id,
                label: label,
                token: this.token,
            }
            this.$confirm("是否删除, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                standardLabelDel(data).then((rec) => {
                    console.log('打印返回', rec)
                    if (rec.code == '200') {
                        this.$message({
                            type: "success",
                            message: rec.message,
                        });
                        this.queryStandard()
                    }
                });
            });
        }
    },
}
</script>
<style scoped>
.right_area {
    float: right;
    flex: auto;
}

/deep/ .litop .el-input {
    width: 245px;
}

/deep/ .litop .el-input__inner {
    width: 204px;
}

.head_form {
    background: #fff;
    padding: 18px 24px;
    display: flex;
}

.head_form .el-form-item {
    display: flex;
    margin-right: 30px;
}

.pageTotal {
    display: flex;
    flex-direction: row-reverse;
    margin: 30px 0;
}

.standards .del_icon {
    margin-left: 20px;
}

.plus_add {
    margin: 20px 0 20px 0;
}

.form_line {}

.form_line .el-form-item {
    width: 70%;
}

/deep/ .operate_dialog {
    width: 700px;
}

/deep/ .stand_form .el-form-item__content {
    display: flex;
}

.operate_dialog .el-radio-group {
    margin-top: 11px;
    margin-left: 24px;
}

/deep/ .stand_form .el-form-item__label {
    width: 100px;
}

/deep/ .label_input .el-input {
    /* width: 70%; */
    margin-right: 11px;
}

.inconent .label_list {
    /* display: flex;
    flex-wrap: wrap;
    align-content: flex-start; */
}

.inconent .label_list .btns {
    margin-right: 8px;
    padding-bottom: 5px;
}

.label_list .lf_label {
    padding-right: 12px;
}

.standards .subtext {
    color: #0099fc;
    cursor: pointer;
}
</style>
